import React from 'react';
import { Review } from 'ekyc-web-module';
import { generateJWE } from '../../helpers/encryption';

const review = ({ sdkToken, identityKycToken, onKycSuccess, onKycFailure,verificationId }) => {
  const basePath = '/v2/baas/kyc';
  const props = {
    baseUrl: process.env.GATSBY_API_ENDPOINT,
    ekycToken: sdkToken,
    identityToken: identityKycToken,
    onKycSuccess,
    onKycFailure,
    basePath,
    generateJWE,
    verificationId
  };
  return <Review {...props} />;
};

export default review;
