import React, { useState, useCallback, useEffect } from 'react';
import { navigate } from 'gatsby';
import QueryString from 'query-string';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageLayout from '../../components/Layout/PageLayout';
import { JumioSdkProps, JumioState } from './types';
import { DATA_CENTER, JUMIO_EVENTS, JumioCallbackStatus } from './helper';
import ReviewPage from './review';
import { JumioStyles } from '../../styles/JumioStyles';

// Note: to declare 'jumio-sdk' element in the JSX namespace
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'jumio-sdk': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      > & { dc: string; token?: string };
      'x-ds-color-scheme-config': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      > & { slot?: string; 'data-color'?: string };
    }
  }
}

const JumioSdk = ({ sdkToken, handleJumioCallback }: JumioSdkProps) => {
  useEffect(() => {
    const script = document.createElement('script');
    // Gatsby serves files from `static`  jumio js is the javascript code
    script.src = '/jumio.js';
    script.type = 'module';
    script.onload = () => {
      if (sdkToken) {
        const jumioSDK = document.querySelector('jumio-sdk');
        if (jumioSDK) {
          jumioSDK.addEventListener(JUMIO_EVENTS.SUCCESS, (event: any) => {
            handleJumioCallback(JumioCallbackStatus.SUCCESS, event?.detail);
          });
          jumioSDK.addEventListener(JUMIO_EVENTS.FAILED, (_) => {
            handleJumioCallback(JumioCallbackStatus.FAILURE);
          });
        }
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [sdkToken]);

  return (
    <>
      <JumioStyles />
      <jumio-sdk
        dc={DATA_CENTER.SG}
        style={{ width: '60%', height: '80%' }}
        token={sdkToken}
       
      >
         <x-ds-color-scheme-config slot="theme" data-color="light"></x-ds-color-scheme-config>
      </jumio-sdk>
    </>
  );
};

const ekyc = () => {
  let search = typeof window !== 'undefined' ? window.location.search : '';
  const [jumioState, setJumioState] = useState<JumioState>(null);
  const { sdkToken, identityKycToken, verificationId } =
    QueryString.parse(search) || {};

  useEffect(() => {
    if (jumioState?.status === JumioCallbackStatus.FAILURE) {
      navigate('/ekyc/failure');
    }
  }, [jumioState]);

  const handleJumioCallback = useCallback(
    (status: JumioCallbackStatus, data?: any) => {
      switch (status) {
        case JumioCallbackStatus.SUCCESS:
          setJumioState({ status: JumioCallbackStatus.SUCCESS, data });
          break;
        case JumioCallbackStatus.FAILURE:
          setJumioState({ status: JumioCallbackStatus.FAILURE });
          break;
        default:
          break;
      }
    },
    [identityKycToken]
  );

  const handleKycSuccess = useCallback(() => {
    navigate('/ekyc/success');
  }, []);

  const handleKycFailure = useCallback((props) => {
    navigate('/ekyc/failure' + search, {
      state: {
        ...props
      }
    });
  }, []);

  if (!sdkToken || !identityKycToken) {
    navigate('/ekyc/failure');
    return null;
  }

  return (
    <>
      {jumioState ? (
        <>
          <Header align="left" />
          {jumioState.status === JumioCallbackStatus.SUCCESS && (
            <ReviewPage
              sdkToken={sdkToken}
              identityKycToken={identityKycToken}
              onKycSuccess={handleKycSuccess}
              onKycFailure={handleKycFailure}
              verificationId={verificationId}
            />
          )}
        </>
      ) : (
        <PageLayout>
          <Header align="left" />
          <JumioSdk
            sdkToken={sdkToken}
            handleJumioCallback={handleJumioCallback}
          />
          <Footer />
        </PageLayout>
      )}
    </>
  );
};

export default ekyc;
