import { createGlobalStyle } from 'styled-components';
// import jumioSdkIcomoonTtf from './fonts/jumio-sdk-icomoon.ttf';
// import jumioSdkIcomoonWoff from './fonts/jumio-sdk-icomoon.woff';
// import jumioSdkIcomoonSvg from '/fonts/jumio-sdk-icomoon.svg';

export const JumioStyles = createGlobalStyle`
  @font-face {
    font-family: 'jumio-sdk-icomoon';
    src:
      url('/fonts/jumio-sdk-icomoon.ttf') format('truetype'),
      url('/fonts/jumio-sdk-icomoon.woff') format('woff'),
      url('/fonts/jumio-sdk-icomoon.svg#jumio-sdk-icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  [class^='icon-'],
  [class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'jumio-sdk-icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-id-document:before {
    content: '\e967';
  }
  .icon-upload-method:before {
    content: '\e968';
  }
  .icon-add:before {
    content: '\e900';
  }
  .icon-add-time:before {
    content: '\e901';
  }
  .icon-analytics:before {
    content: '\e902';
  }
  .icon-arrow-direction-down:before {
    content: '\e903';
  }
  .icon-arrow-direction-left:before {
    content: '\e904';
  }
  .icon-arrow-direction-right:before {
    content: '\e905';
  }
  .icon-arrow-direction-up:before {
    content: '\e906';
  }
  .icon-attachment:before {
    content: '\e907';
  }
  .icon-business .path1:before {
    content: '\e908';
    color: rgb(92, 101, 112);
    opacity: 0.5;
  }
  .icon-business .path2:before {
    content: '\e909';
    margin-left: -1em;
    color: rgb(92, 101, 112);
  }
  .icon-business .path3:before {
    content: '\e90a';
    margin-left: -1em;
    color: rgb(92, 101, 112);
  }
  .icon-business .path4:before {
    content: '\e90b';
    margin-left: -1em;
    color: rgb(92, 101, 112);
  }
  .icon-calendar .path1:before {
    content: '\e90c';
    color: rgb(92, 101, 112);
  }
  .icon-calendar .path2:before {
    content: '\e90d';
    margin-left: -1em;
    color: rgb(92, 101, 112);
  }
  .icon-calendar .path3:before {
    content: '\e90e';
    margin-left: -1em;
    color: rgb(92, 101, 112);
    opacity: 0.5;
  }
  .icon-camera-video:before {
    content: '\e90f';
  }
  .icon-check:before {
    content: '\e910';
  }
  .icon-check-circle:before {
    content: '\e911';
  }
  .icon-check-circle-filled:before {
    content: '\e912';
  }
  .icon-chevron-direction-down:before {
    content: '\e913';
  }
  .icon-chevron-direction-left:before {
    content: '\e914';
  }
  .icon-chevron-direction-right:before {
    content: '\e915';
  }
  .icon-chevron-direction-up:before {
    content: '\e916';
  }
  .icon-close:before {
    content: '\e917';
  }
  .icon-collapse:before {
    content: '\e918';
  }
  .icon-columns:before {
    content: '\e919';
  }
  .icon-compare:before {
    content: '\e91a';
  }
  .icon-copy:before {
    content: '\e91b';
  }
  .icon-create-new:before {
    content: '\e91c';
  }
  .icon-delete:before {
    content: '\e91d';
  }
  .icon-device:before {
    content: '\e91e';
  }
  .icon-download:before {
    content: '\e91f';
  }
  .icon-edit:before {
    content: '\e920';
  }
  .icon-email:before {
    content: '\e921';
  }
  .icon-exit:before {
    content: '\e922';
  }
  .icon-exclamation-mark:before {
    content: '\e923';
  }
  .icon-expand:before {
    content: '\e924';
  }
  .icon-external:before {
    content: '\e925';
  }
  .icon-filter:before {
    content: '\e926';
  }
  .icon-globe:before {
    content: '\e927';
  }
  .icon-help:before {
    content: '\e928';
  }
  .icon-id-back:before {
    content: '\e929';
  }
  .icon-id-digital:before {
    content: '\e92a';
  }
  .icon-id-drivers-license:before {
    content: '\e92b';
  }
  .icon-id-front:before {
    content: '\e92c';
  }
  .icon-id-passport:before {
    content: '\e92d';
  }
  .icon-id-work-visa:before {
    content: '\e92e';
  }
  .icon-info:before {
    content: '\e92f';
  }
  .icon-initiate:before {
    content: '\e930';
  }
  .icon-jump-back:before {
    content: '\e931';
  }
  .icon-jump-forward:before {
    content: '\e932';
  }
  .icon-link:before {
    content: '\e933';
  }
  .icon-list-view:before {
    content: '\e934';
  }
  .icon-lock:before {
    content: '\e935';
  }
  .icon-menu:before {
    content: '\e936';
  }
  .icon-minus-circle:before {
    content: '\e937';
  }
  .icon-more-actions:before {
    content: '\e938';
  }
  .icon-notification-filled .path1:before {
    content: '\e939';
    color: rgb(92, 101, 112);
    opacity: 0.25;
  }
  .icon-notification-filled .path2:before {
    content: '\e93a';
    margin-left: -1em;
    color: rgb(92, 101, 112);
  }
  .icon-notification-filled .path3:before {
    content: '\e93b';
    margin-left: -1em;
    color: rgb(92, 101, 112);
  }
  .icon-party:before {
    content: '\e93c';
  }
  .icon-payment-method .path1:before {
    content: '\e93d';
    color: rgb(92, 101, 112);
    opacity: 0.3;
  }
  .icon-payment-method .path2:before {
    content: '\e93e';
    margin-left: -1em;
    color: rgb(92, 101, 112);
    opacity: 0.3;
  }
  .icon-payment-method .path3:before {
    content: '\e93f';
    margin-left: -1em;
    color: rgb(92, 101, 112);
  }
  .icon-plus-circle:before {
    content: '\e940';
  }
  .icon-reset:before {
    content: '\e941';
  }
  .icon-risk:before {
    content: '\e942';
  }
  .icon-rotate-left:before {
    content: '\e943';
  }
  .icon-rotate-right:before {
    content: '\e944';
  }
  .icon-schedule:before {
    content: '\e945';
  }
  .icon-search:before {
    content: '\e946';
  }
  .icon-share:before {
    content: '\e947';
  }
  .icon-shield:before {
    content: '\e948';
  }
  .icon-sort:before {
    content: '\e949';
  }
  .icon-sort-ascending:before {
    content: '\e94a';
  }
  .icon-sort-descending:before {
    content: '\e94b';
  }
  .icon-table-view:before {
    content: '\e94c';
  }
  .icon-timeline:before {
    content: '\e94d';
  }
  .icon-transaction .path1:before {
    content: '\e94e';
    color: rgb(92, 101, 112);
    opacity: 0.3;
  }
  .icon-transaction .path2:before {
    content: '\e94f';
    margin-left: -1em;
    color: rgb(92, 101, 112);
  }
  .icon-transaction .path3:before {
    content: '\e950';
    margin-left: -1em;
    color: rgb(92, 101, 112);
  }
  .icon-verification:before {
    content: '\e951';
  }
  .icon-unlink:before {
    content: '\e952';
  }
  .icon-unlock:before {
    content: '\e953';
  }
  .icon-unschedule:before {
    content: '\e954';
  }

  .icon-upload1:before {
    content: '\e955';
  }
  .icon-user:before {
    content: '\e956';
  }
  .icon-version-history:before {
    content: '\e957';
  }
  .icon-visibility:before {
    content: '\e958';
  }
  .icon-visibility-off:before {
    content: '\e959';
  }
  .icon-zoom-in:before {
    content: '\e95a';
  }
  .icon-zoom-out:before {
    content: '\e95b';
  }
  .icon-info-filled .path1:before {
    content: '\e95c';
    color: rgb(92, 101, 112);
    opacity: 0.3;
  }
  .icon-info-filled .path2:before {
    content: '\e95d';
    margin-left: -1em;
    color: rgb(92, 101, 112);
  }
  .icon-info-filled .path3:before {
    content: '\e95e';
    margin-left: -1em;
    color: rgb(92, 101, 112);
  }
  .icon-info-filled .path4:before {
    content: '\e95f';
    margin-left: -1em;
    color: rgb(92, 101, 112);
  }
  .icon-jumio:before {
    content: '\e960';
  }
  .icon-ic-capture-camera:before {
    content: '\e961';
  }
  .icon-ic-capture-camera-flash:before {
    content: '\e962';
  }
  .icon-ic-capture-flip:before {
    content: '\e963';
  }
  .icon-ic-capture-switch:before {
    content: '\e964';
  }
  .icon-ic-method-camera:before {
    content: '\e965';
  }
  .icon-camera-number-placeholder:before {
    content: '\e966';
  }
`;
